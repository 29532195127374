import {
  DELETE_ITEM,
  DELETE_ORDER,
  DELETE_ATTRIBUTE,
  UPDATE_BULK_ITEM,
  DELETE_CONDITION,
  UPDATE_BULK_ORDER,
} from 'actions/types';
import { transformActions } from 'utils/';
import { SHOW_CONFIRM, CLEAR_CONFIRM } from 'constants/modal';

const initialState = {
  type: '',
  title: '',
  caption: '',
  options: {
    deleting: false,
  },
  onCancel: Function,
  onConfirm: Function,
};

function confirm(state = initialState, action) {
  const actions = [
    DELETE_ITEM,
    DELETE_ORDER,
    DELETE_CONDITION,
    UPDATE_BULK_ITEM,
    DELETE_ATTRIBUTE,
    UPDATE_BULK_ORDER,
  ];
  const success = transformActions(actions, 'SUCCESS');
  const { type, payload } = action;
  if (type === SHOW_CONFIRM) {
    return payload;
  }
  if (type === CLEAR_CONFIRM) {
    return initialState;
  }
  if (success.includes(type)) {
    return initialState;
  }
  return state;
}

export default confirm;
