import { endPoints } from 'api/';
import { active } from 'constants/status';
import { GET_STOCK_CONDITION } from 'actions/types';

/**
 * @description Get chart data for stock grouped by condition
 * @param {{
 *  companyId : string,
 * }} params
 * @returns Chart data object
 */
export function getStockCondition() {
  /**
   * @param {Function} dispatch
   */
  return function (dispatch) {
    const query = `?status=${active}`;
    const action = {
      type: GET_STOCK_CONDITION,
      /**
       * @param {Function} apiClient
       */
      request: async function (apiClient) {
        const data = await apiClient(endPoints.GET_STOCK_CONDITION, {}, query);
        return data;
      },
    };
    dispatch(action);
  };
}
