import { GET_DEFAULT_STATUSES } from 'actions/types';

let statusList = [];
const initialState = { data: [], isLoading: false };

/**
 * @description Stores default status array details in redux state.
 * @returns {[]}
 * @param {[]} state
 * @param {{}} action
 */
export function defaultStatus(state = initialState, action) {
  const { type, payload } = action;
  if (type === GET_DEFAULT_STATUSES) {
    return { isLoading: false, data: statusList };
  }
  if (type === `${GET_DEFAULT_STATUSES}_SUCCESS`) {
    statusList = payload;
    return { isLoading: false, data: statusList };
  }
  if (type === `${GET_DEFAULT_STATUSES}_FAILURE`) {
    return { isLoading: false, data: statusList };
  }
  return state;
}
