import { maroon } from 'constants/color';
import styled, { css } from 'styled-components';

export const StyledTable = styled.table`
  --width: ${({ width }) => width || 100}%;
  border-spacing: 0;
  width: var(--width);
  margin-bottom: 20px;
  max-width: var(--width);
  border-collapse: collapse;
  background-color: transparent;
`;

export const StyledTableBody = styled.tbody``;

export const StyledTableHead = styled.thead`
  height: 40px;
`;

export const StyledTableFoot = styled.tfoot``;

export const StyledTableData = styled.td`
  height: 80px;
  font-size: 12px;
  line-height: 18px;
  font-style: normal;
  padding-left: 20px;
  font-weight: normal;
  ${function ({ $mini }) {
    return (
      $mini &&
      css`
        height: 40px;
      `
    );
  }}
  ${function ({ $center }) {
    return (
      $center &&
      css`
        text-align: center;
      `
    );
  }}
&[aria-disabled='true'] {
    color: #a0a0a0;
  }
`;

export const StyledTableButtonWrapper = styled.div`
  &:first-child {
    button {
      margin-right: 2px !important;
    }
  }
`;

export const StyledTableRow = styled.tr`
  --primaryColor: ${({ theme }) => theme.primaryColor};
  --tableHoverColor: ${({ theme }) => theme.tableHoverColor};

  &:hover {
    background-color: var(--tableHoverColor);

    ${StyledTableButtonWrapper} {
      background-color: var(--tableHoverColor);

      button {
        background-color: var(--tableHoverColor);
      }
    }
  }

  &:not(:first-child) {
    ${StyledTableData} {
      border-top: 1px solid var(--primaryColor);
    }
  }

  &:last-child {
    ${StyledTableData} {
      border-bottom: 1px solid var(--primaryColor);
    }
  }
`;

export const IconWrapper = styled.span`
  padding: 5px;
  vertical-align: middle;
`;

export const StyledMessage = styled.p`
  width: 100%;
  font-size: 14px;
  color: ${maroon};
  text-align: center;
`;

export const StyledNoDataTableData = styled.td`
  border-bottom: 1px solid #c4c4c4;
`;
