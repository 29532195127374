import {
  bool,
  func,
  node,
  number,
  object,
  string,
  oneOfType,
} from 'prop-types';
import React from 'react';
import {
  StyledInput,
  StyledWrapper,
  StyledCaption,
  StyledCheckMark,
  StyledDescription,
  StyledSrOnlyLabel,
} from 'components/Inputs/CheckBox/CheckBox.styled';

/**
 * @param {{
 * caption : string
 * inputId : string
 * inputName : string
 * isHeader : boolean
 * onChange : Function
 * isChecked : boolean
 * inputTestId : string
 * description : string
 * marginRight : number
 * showCaption : boolean
 * }} param
 */
function CheckBox({
  caption,
  inputId,
  onChange,
  isChecked,
  inputName,
  inputTestId,
  marginRight = 0,
  isHeader = false,
  description = '',
  showCaption = true,
}) {
  const animation = {
    whileTap: { scale: 0.99 },
    whileHover: { scale: 1.01 },
  };
  return (
    <StyledWrapper
      {...animation}
      htmlFor={inputId}
      $header={isHeader}
      $marginRight={marginRight}
    >
      {showCaption ? (
        <StyledCaption htmlFor={inputId}>{caption}</StyledCaption>
      ) : (
        <StyledSrOnlyLabel htmlFor={inputId}>{caption}</StyledSrOnlyLabel>
      )}
      <StyledInput
        id={inputId}
        type="checkbox"
        name={inputName}
        onChange={onChange}
        checked={isChecked}
        data-testid={inputTestId}
      />
      <StyledCheckMark checked={isChecked} />
      {description ? (
        typeof description === 'string' ? (
          <StyledDescription>{description}</StyledDescription>
        ) : (
          description
        )
      ) : null}
    </StyledWrapper>
  );
}

CheckBox.defaultProps = {
  isChecked: false,
};

CheckBox.propTypes = {
  onChange: func,
  isHeader: bool,
  isChecked: bool,
  caption: string,
  inputId: string,
  inputName: string,
  showCaption: bool,
  inputTestId: string,
  marginRight: number,
  description: oneOfType([node, string, object]),
};

export default CheckBox;
