import { combineReducers } from 'redux';

// item
import {
  tag,
  item,
  upload,
  attribute,
  stockMove,
  condition,
  itemHistory,
  defaultStatus,
  stockCondition,
  category as categories,
} from 'reducers/item/';
// order
import {
  order,
  history as orderHistory,
  assigned as assignedOrder,
} from 'reducers/order/';
// logs
import logs from 'reducers/logs/logs';
// theme
import theme from 'reducers/theme/theme';
// tasks
import tasks from 'reducers/tasks/tasks';
// modal
import modal from 'reducers/modals/modal';
// assets
import assets from 'reducers/assets/assets';
// notify
import notify from 'reducers/notify/notify';
// user
import user from 'reducers/users/user/user';
// people
import people from 'reducers/people/people';
// company
import company from 'reducers/company/company';
// confirm
import confirm from 'reducers/confirm/confirm';
// users
import users from 'reducers/users/users/users';
// customer
import customer from 'reducers/customer/customer';
// storages
import storages from 'reducers/storages/storages';
// password
import password from 'reducers/users/password/password';
// onboarding
import onboarding from 'reducers/onboarding/onboarding';
// authentication
import isLoggedOut from 'reducers/isLoggedOut/isLoggedOut';
import logInStatus from 'reducers/logInStatus/logInStatus';
// active menu
import activeMenu from 'reducers/menus/activeMenu/activeMenu';
// notification
import notification from 'reducers/notification/notification';
// warehouses
import { warehouses, warehouseUsage } from 'reducers/warehouses/';
// assignedAssets
import assignedAssets from 'reducers/assignedAssets/assignedAssets';
// top menu bar
import activeTopMenu from 'reducers/menus/activeTopMenu/activeTopMenu';

function rootReducer() {
  return combineReducers({
    tag,
    logs,
    user,
    item,
    modal,
    theme,
    users,
    tasks,
    order,
    notify,
    upload,
    assets,
    people,
    company,
    confirm,
    password,
    customer,
    storages,
    condition,
    attribute,
    stockMove,
    activeMenu,
    onboarding,
    warehouses,
    categories,
    isLoggedOut,
    logInStatus,
    itemHistory,
    orderHistory,
    notification,
    defaultStatus,
    assignedOrder,
    activeTopMenu,
    stockCondition,
    assignedAssets,
    warehouseUsage,
  });
}

export default rootReducer;
