import {
  getOrderNumber,
  arrayToSentence,
  objectHasProperty,
  capitalizeFirstLetter,
} from 'utils/';
import {
  DropDown,
  RoundButton,
  CancelButton,
  DeleteButton,
} from 'components/Buttons/';
import {
  GOOD,
  EMAIL,
  LEASE,
  PRINT,
  PENDING,
  ARCHIVED,
  RETURNED,
  CANCELLED,
  NO_ATTRIBUTES,
} from 'constants/inventory';
import { history } from 'store/store';
import { Tab } from 'components/Tab/';
import {
  StyledHeader,
  StyledButtonRow,
  StyledOrderNumber,
  StyledButtonWrapper,
  StyledStatusWrapper,
  StyledGeneralWrapper,
} from 'components/Order/Form/Form.styled';
import {
  bool,
  func,
  oneOf,
  shape,
  object,
  string,
  arrayOf,
  oneOfType,
} from 'prop-types';
import { useParams } from 'react-router-dom';
import { FlexRow } from 'components/Layout/';
import { black, white } from 'constants/color';
import { SelectInput } from 'components/Inputs/';
import { UPDATE_ORDER, CREATE_ORDER } from 'actions/types';
import { ReactComponent as TickIcon } from 'icons/tick.svg';
import { ORDER_PATH, RETURN_ORDER_PATH } from 'constants/general';
import { ReactComponent as EditIcon } from 'icons/edit_circle.svg';
import React, { useRef, useMemo, useState, useEffect } from 'react';
import { useTagOption, useHttpStatus, useSelectOption } from 'hooks/';
import { General, Catalogue, History, Returned } from 'components/Order/';

/**
 * @param {{
 * tags: [],
 * users: [],
 * order: {},
 * currency: [],
 * statuses: [],
 * customers: [],
 * inventory: [],
 * warehouses: [],
 * orderHistory: {},
 * onSubmit: Function,
 * onPrintShare: Function,
 * }} param
 */
function Form({
  tags,
  users,
  order,
  statuses,
  currency,
  onSubmit,
  customers,
  inventory,
  warehouses,
  onPrintShare,
  orderHistory,
}) {
  const { id } = useParams();
  const messageRef = useRef();
  const currencyRef = useRef();
  const { response } = useHttpStatus({
    actions: [CREATE_ORDER, UPDATE_ORDER],
  });
  const pending = useTagOption({ name: PENDING });
  const archived = useTagOption({ name: ARCHIVED });
  const returned = useTagOption({ name: RETURNED });
  const cancelled = useTagOption({ name: CANCELLED });
  const {
    settings,
    name = '',
    notes = '',
    assignees = [],
    status = pending,
    order_type: orderType = '',
    customer_id: customerId = '',
    warehouses: orderWarehouses = [],
    currency: orderCurrency = currency[0],
  } = order;
  const {
    user_id: userId = '',
    id: assignmentId = '',
    return_date: returnDate = '',
    delivery_date: deliveryDate = '',
  } = assignees[0] || {};
  const isArchived = status === archived;
  const isReturned = status === returned;
  const isCancelled = status === cancelled;
  const statusName = useTagOption({ id: status });
  const { includeLeasePrice = true } = settings || {};
  const orderNumber = getOrderNumber({ id, prefix: '#' });
  const emptyCatalogue = {
    stock: 0,
    unit: '',
    name: '',
    price: 0,
    quantity: 0,
    attributes: [],
  };
  const stateObject = {
    status,
    type: orderType,
    assignee: userId,
    includeLeasePrice,
    name: unescape(name),
    terms: unescape(notes),
    currency: orderCurrency,
    returnDate: +returnDate,
    warehouses: orderWarehouses,
    deliveryDate: +deliveryDate,
    customer: unescape(customerId),
  };
  const [errors, setErrors] = useState({
    name: '',
    type: '',
    item: '',
    terms: '',
    items: '',
    assignee: '',
    customer: '',
    quantity: '',
    returnDate: '',
    deliveryDate: '',
  });
  const printShareOptions = [
    {
      value: PRINT,
      label: 'Print',
    },
    {
      value: EMAIL,
      label: 'Email',
    },
  ];
  const statusOptions = useSelectOption({
    items: tags,
    label: 'tagname',
    capitalize: true,
    exclude: [status],
  });
  const [message, setMessage] = useState('');
  const [state, setState] = useState(stateObject);
  const [isReadOnly, setIsReadOnly] = useState(!!id);
  const [returnedItems, setReturnedItems] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [catalogue, setCatalogue] = useState([emptyCatalogue]);

  const good = useMemo(() => {
    return statuses.find(function (s) {
      return s.status_name === GOOD;
    });
  }, [statuses]);

  const totalCost = useMemo(() => {
    return catalogue.reduce(function (acc, { price, quantity }) {
      return acc + price * (quantity || 1);
    }, 0);
  }, [catalogue]);

  /**
   * @param {{
   * currentTarget : {
   *   name : string
   *   value : string
   * }
   * }} event
   */
  function onInputChange(event) {
    const { currentTarget } = event;
    const { name, value } = currentTarget;
    setState({
      ...state,
      [name]: value,
    });
  }

  function onStatusChange(option) {
    if (option) {
      const { value } = option;
      const { assignees: _, delivery_location: __, ...rest } = order;
      const payload = {
        ...rest,
        status: value,
        skipAssignment: true,
      };
      onSubmit(payload);
    }
  }

  function onSharePrint(option) {
    const payload = { id, option };
    if (option === EMAIL) {
      const customer = customers.find(function ({ id }) {
        return id === order?.customer_id;
      });
      payload.email = customer?.email || '';
    }
    onPrintShare({ payload });
  }

  function onCancelOrder() {
    const { assignees: _, delivery_location: __, ...rest } = order;
    const payload = {
      ...rest,
      status: cancelled,
      skipAssignment: true,
    };
    onSubmit(payload);
  }

  function onDeleteOrder() {
    const payload = {
      ...order,
      status: archived,
      skipAssignment: true,
    };
    onSubmit(payload, true);
  }

  function getCatalogueAttributes(itemId, attributeName, count) {
    const item = inventory.find(function (item) {
      return item.id === itemId;
    });
    if (item) {
      const { attributes } = item;
      const filtered = attributes?.filter(function (attr) {
        return attr.quantity > 0 && attr.attribute_name === attributeName;
      });
      const sliced = filtered?.slice(0, count);
      const attribute = sliced.map(function (attr) {
        return { id: attr.id, quantity: 1 };
      });
      return attribute.flat();
    }
  }

  function formatOrderItems() {
    return catalogue.map(function (item) {
      const { unit, name, itemId, quantity, attributes } = item;
      const filtered = attributes.filter(function ({ name }) {
        return name !== NO_ATTRIBUTES;
      });
      const catAttributes = filtered.map(function ({ name, quantity }) {
        return getCatalogueAttributes(itemId, name, quantity);
      });
      return {
        name,
        quantity,
        id: itemId,
        quantity_unit: unit,
        attributes: catAttributes.flat(),
      };
    });
  }

  function onSubmitOrder() {
    setIsSubmitting(true);
    const isFormValid = validateOrderForm();
    if (isFormValid) {
      const {
        type,
        name,
        terms,
        status,
        assignee,
        customer,
        warehouses,
        returnDate,
        deliveryDate,
        includeLeasePrice,
      } = state;
      const props = {};
      if (type === LEASE) {
        props.returnDate = returnDate;
      }
      if (deliveryDate) {
        props.deliveryDate = deliveryDate;
      }
      let currency = orderCurrency;
      if (currencyRef.current) {
        const { current } = currencyRef;
        currency = current.options[current.selectedIndex].value;
      }
      const payload = {
        name,
        status,
        ...props,
        currency,
        warehouses,
        assignmentId,
        notes: terms,
        userId: assignee,
        order_type: type,
        settings: {
          includeLeasePrice,
        },
        customer_id: customer,
        total_cost: totalCost,
        items: formatOrderItems(),
      };
      onSubmit(payload);
    }
  }

  function validateOrderForm() {
    let isFormValid = true;
    const optionalFields = [
      'terms',
      'quantity',
      'assignee',
      'warehouses',
      'returnDate',
      'deliveryDate',
      'includeLeasePrice',
    ];
    let errorObject = { ...errors };
    const { type, assignee, returnDate, deliveryDate } = state;
    for (const [key, value] of Object.entries(state)) {
      if (!optionalFields.includes(key) && !value) {
        isFormValid = false;
        errorObject = {
          ...errorObject,
          [key]: `${capitalizeFirstLetter(key, true)} is required`,
        };
      } else {
        errorObject = {
          ...errorObject,
          [key]: '',
        };
      }
    }
    if (type === LEASE && !returnDate) {
      isFormValid = false;
      errorObject = {
        ...errorObject,
        returnDate: 'Required for lease order',
      };
    } else {
      errorObject = {
        ...errorObject,
        returnDate: '',
      };
    }
    if (assignee && !deliveryDate) {
      isFormValid = false;
      errorObject = {
        ...errorObject,
        deliveryDate: 'Return date is required',
      };
    } else {
      errorObject = {
        ...errorObject,
        deliveryDate: '',
      };
    }
    setErrors(errorObject);
    if (catalogue.length === 1 && catalogue[0].name === '') {
      isFormValid = false;
      setMessage('No Items In This Order');
      scrollToMessage();
    } else {
      setMessage('');
      const invalidItems = [];
      catalogue.forEach(function ({ name, quantity }) {
        if (!name || !quantity) {
          invalidItems.push(name);
          isFormValid = false;
        }
      });
      if (invalidItems.length > 0) {
        const message = arrayToSentence({
          array: invalidItems,
          prefix: 'Invalid items:',
        });
        isFormValid = false;
        setMessage(message);
        scrollToMessage();
      }
    }
    return isFormValid;
  }

  function scrollToMessage() {
    if (messageRef.current) {
      messageRef.current.scrollIntoView({
        block: 'end',
        behavior: 'smooth',
      });
    }
  }

  /**
   * @param {string} itemId
   */
  function getItemDetails(itemId) {
    const item = inventory.find(function (item) {
      return item.id === itemId;
    });
    if (item) {
      const leasePrice = +item.leasing_price || 0;
      const lease = item.item_order_type === LEASE;
      const sellPrice = +item.other_details?.sellPrice || 0;
      return {
        name: item.item_name,
        stock: item.quantity,
        unit: item.quantity_unit,
        price: lease ? leasePrice : sellPrice,
      };
    }
    return {
      price: 0,
      stock: 0,
    };
  }

  function onEnableEditing() {
    setIsReadOnly(false);
  }

  function onCancel() {
    history.push(ORDER_PATH);
  }

  function onReturnOrder() {
    const path = RETURN_ORDER_PATH.replace(':id', id);
    history.push(path);
  }

  /**
   * @param {[]} attributes
   */
  function getActiveGoodAttributes(attributes) {
    const data = attributes.filter(function ({ condition, quantity }) {
      return quantity > 0 && good?.id === condition;
    });
    return data;
  }

  /**
   * @param {[]} attributes
   */
  function getTotalItemAttributes(attributes) {
    const active = getActiveGoodAttributes(attributes);
    const total = active.reduce(function (acc, { quantity }) {
      return acc + quantity;
    }, 0);
    return total;
  }

  /**
   * @param {string} itemId
   * @param {string} attributeName
   * @returns {[]}
   */
  function getActiveItemAttributes(itemId, attributeName) {
    const item = inventory.find(function ({ id }) {
      return id === itemId;
    });
    const active = getActiveGoodAttributes(item?.attributes);
    const attributes = active.filter(function (attr) {
      return attr.quantity > 0 && attr.attribute_name === attributeName;
    });
    return attributes;
  }

  /**
   * @param {string} itemId
   * @param {[]} attributes
   * @param {number} quantity
   */
  function getItemAttribute(itemId, attributes, quantity) {
    const item = inventory.find(function ({ id }) {
      return id === itemId;
    });
    if (item) {
      const stock = item.quantity;
      const unknown = stock - item.attributes.length;
      const mapped = attributes.map(function (attr) {
        const attribute = item.attributes.find(function ({ id }) {
          return attr.id === id;
        });
        if (attribute) {
          return { ...attr, name: attribute.attribute_name };
        }
        return attr;
      });
      const grouped = mapped.reduce(function (acc, attr) {
        const { name, quantity } = attr;
        if (!acc[name]) {
          acc[name] = { stock: 0, quantity: 0 };
        }
        acc[name] = {
          name: attr.name,
          quantity: acc[name].quantity + quantity,
          stock: getActiveItemAttributes(itemId, name).length,
        };
        return acc;
      }, {});
      const attr = Object.values(grouped);
      if (unknown > 0) {
        return [
          ...attr,
          {
            stock: unknown,
            name: NO_ATTRIBUTES,
            quantity: quantity - getTotalItemAttributes(attr),
          },
        ];
      }
      return attr;
    }
    return attributes;
  }

  useEffect(() => {
    if (isSubmitting) {
      validateOrderForm();
    }
  }, [state, isSubmitting]);

  useEffect(() => {
    if (response && response.id) {
      setIsReadOnly(true);
      history.push(`${ORDER_PATH}#${state.type}`);
    }
  }, [response]);

  useEffect(() => {
    if (objectHasProperty(order, 'id')) {
      setState(stateObject);
      const { items } = order;
      if (!items.length) {
        setCatalogue([emptyCatalogue]);
      } else {
        const returned = items.filter(function (item) {
          return item?.returned == true;
        });
        const returnedItems = returned.map(function (item) {
          const { id, quantity, attributes = [] } = item;
          return {
            ...item,
            attributes: getItemAttribute(id, attributes, quantity),
          };
        });
        setReturnedItems(returnedItems);
        const newCatalogue = items.map(function (itm) {
          const {
            id,
            name,
            quantity,
            attributes = [],
            quantity_unit: unit,
          } = itm;
          const { price, stock } = getItemDetails(id);
          return {
            unit,
            name,
            stock,
            price,
            quantity,
            itemId: id,
            attributes: getItemAttribute(id, attributes, quantity),
          };
        });
        setCatalogue(newCatalogue);
      }
    } else {
      setState({ ...stateObject, currency: orderCurrency });
    }
  }, [order, inventory]);

  const options = [
    {
      name: 'items',
      caption: 'Order Items',
      children: (
        <Catalogue
          good={good}
          type={state.type}
          message={message}
          total={totalCost}
          statuses={statuses}
          inventory={inventory}
          catalogue={catalogue}
          isReadOnly={isReadOnly}
          messageRef={messageRef}
          currency={orderCurrency}
          currencyRef={currencyRef}
          setCatalogue={setCatalogue}
          warehouses={state.warehouses}
          emptyCatalogue={emptyCatalogue}
          getItemDetails={getItemDetails}
          includeLeasePrice={state.includeLeasePrice}
          getActiveGoodAttributes={getActiveGoodAttributes}
          getActiveItemAttributes={getActiveItemAttributes}
        />
      ),
    },
    {
      name: 'history',
      caption: 'Order History',
      children: <History orderHistory={orderHistory} />,
    },
    {
      name: 'returned',
      caption: 'Returned Items',
      children: <Returned items={returnedItems} />,
    },
  ];

  return (
    <>
      {id ? (
        <StyledHeader>
          <StyledOrderNumber>Order {orderNumber}</StyledOrderNumber>
          {isArchived || isCancelled || isReturned ? null : (
            <StyledStatusWrapper>
              <SelectInput
                name="status"
                caption="status"
                marginBottom={5}
                isLoading={false}
                isClearable={true}
                showCaption={false}
                isSearchable={true}
                ariaLabelledBy="status"
                options={statusOptions}
                onChange={onStatusChange}
                placeholder="Update status"
              />
            </StyledStatusWrapper>
          )}
          {isArchived || isCancelled || isReturned ? null : (
            <>
              <RoundButton
                flexWidth={20}
                caption="Return Order"
                onClick={onReturnOrder}
              />
              <RoundButton
                flexWidth={20}
                backColor="#097F98"
                caption="Cancel Order"
                onClick={onCancelOrder}
              />
            </>
          )}
          <DropDown
            flexWidth={30}
            caption="Share"
            backColor={white}
            borderColor={black}
            onCallBack={onSharePrint}
            options={printShareOptions}
          />
        </StyledHeader>
      ) : null}
      <StyledGeneralWrapper>
        <General
          users={users}
          state={state}
          userId={userId}
          errors={errors}
          setState={setState}
          orderType={orderType}
          customers={customers}
          statusName={statusName}
          isReadOnly={isReadOnly}
          customerId={customerId}
          warehouses={warehouses}
          onInputChange={onInputChange}
          orderWarehouses={orderWarehouses}
        />
      </StyledGeneralWrapper>
      <Tab options={options} minHeight={350} maxHeight={700} marginRight={10} />
      <StyledButtonRow>
        <FlexRow>
          {isArchived || isCancelled || isReturned ? null : isReadOnly ? (
            <StyledButtonWrapper>
              <RoundButton
                caption="Edit"
                flexWidth={100}
                icon={<EditIcon />}
                onClick={onEnableEditing}
              />
            </StyledButtonWrapper>
          ) : (
            <StyledButtonWrapper>
              <RoundButton
                caption="Save"
                flexWidth={100}
                icon={<TickIcon />}
                onClick={onSubmitOrder}
              />
            </StyledButtonWrapper>
          )}
          <StyledButtonWrapper>
            <CancelButton onClick={onCancel} />
          </StyledButtonWrapper>
          {id ? (
            isArchived || isReturned ? null : (
              <StyledButtonWrapper>
                <DeleteButton onClick={onDeleteOrder} />
              </StyledButtonWrapper>
            )
          ) : null}
        </FlexRow>
      </StyledButtonRow>
    </>
  );
}

Form.propTypes = {
  onSubmit: func,
  tags: arrayOf(object),
  users: arrayOf(object),
  orderHistory: shape({
    isLoading: bool,
    data: arrayOf(object),
  }),
  currency: arrayOf(string),
  customers: arrayOf(object),
  inventory: arrayOf(object),
  warehouses: arrayOf(string),
  order: oneOfType([object, oneOf([null])]),
};

export default Form;
