import { motion } from 'framer-motion';
import { black, primary } from 'constants/color';
import styled, { css, keyframes } from 'styled-components';

const noScroll = css`
  &::-webkit-scrollbar {
    display: none;
  }
`;

const slideUp = keyframes`
  from {
    transform: translateY(10px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const StyledWrapper = styled.div`
  --borderRadius: ${({ theme }) => theme.borderRadius};
  height: 100%;
  display: flex;
  overflow: hidden;
  align-items: left;
  flex-direction: column;
  border-bottom-left-radius: var(--borderRadius);
  min-height: ${({ $minHeight }) => $minHeight}px;
  max-height: ${({ $maxHeight }) => $maxHeight}px;
  border-bottom-right-radius: var(--borderRadius);
  margin-right: ${({ $marginRight }) => $marginRight}px;
  background: ${({ theme }) => theme.activeTabBackColor};
  margin-bottom: ${({ $marginBottom }) => $marginBottom}px;

  /* Laptops and Desktops */
  @media (min-width: 1025px) and (max-width: 1280px) {
    width: 100%;
  }
`;

export const StyledNav = styled.nav`
  background: rgb(244, 244, 244);
`;

export const StyledMain = styled.main`
  height: 100%;
  padding-left: 10px;

  /* Mobile Devices */
  @media (max-width: 480px) {
    padding: 5px;
  }
`;

export const StyledList = styled.ul`
  margin: 0;
  padding: 0;
  width: auto;
  display: flex;
  font-size: 14px;
  list-style: none;
  font-weight: 500;
  /* Mobile Devices */
  @media (max-width: 480px) {
    ${noScroll};
    display: flex;
    overflow: auto;
  }
  /* Portrait Tablets and Ipads */
  @media (min-width: 768px) and (max-width: 1024px) {
    ${noScroll};
    display: flex;
    overflow: auto;
  }
`;

export const StyledListItem = styled.li`
  margin: 0;
  padding: 0;
  width: auto;
  height: 24px;
  display: flex;
  font-size: 14px;
  cursor: pointer;
  min-width: 70px;
  color: ${black};
  list-style: none;
  font-weight: 500;
  user-select: none;
  padding: 10px 15px;
  position: relative;
  align-items: center;
  justify-content: center;
  &:disabled {
    ${({ theme }) => theme.disabled}
  }
  ${function ({ $active, $disabled }) {
    return $active
      ? css`
          color: ${primary};
          background: ${({ theme }) => theme.activeTabBackColor};
        `
      : $disabled
      ? css`
          cursor: not-allowed;
        `
      : css`
          &:hover {
            color: ${({ theme }) => theme.primaryColor};
          }
        `;
  }}

  /* Mobile Devices */
  @media (max-width: 480px) {
    min-width: auto;
    white-space: nowrap;
  }

  /* Portrait Tablets and Ipads */
  @media (min-width: 768px) and (max-width: 1024px) {
    min-width: auto;
    white-space: nowrap;
  }
`;

export const StyledIndicator = styled(motion.div)`
  left: 0;
  right: 0;
  top: 0px;
  height: 2px;
  position: absolute;
  background: ${({ theme }) => theme.primaryColor};
`;

export const StyledBody = styled(motion.div)`
  --maxHeight: ${function ({ $maxHeight }) {
    return $maxHeight ? `${$maxHeight}px` : '350px';
  }};

  display: none;
  height: calc(100% - 13%);

  ${({ theme }) => theme.scrollbar};
  overflow: ${({ $autoScroll }) => ($autoScroll ? 'auto' : 'hidden')};
  padding-right: ${({ $autoScroll }) => ($autoScroll ? '10px' : '0')};

  /* Mobile Devices */
  @media (max-width: 480px) {
    width: 100%;
  }

  /* Portrait Tablets and Ipads */
  @media (min-width: 768px) and (max-width: 1024px) {
    max-height: calc(var(--maxHeight) + 390px);
  }

  ${function ({ $active }) {
    return (
      $active &&
      css`
        display: block;
        animation: ${slideUp} 0.2s ease-in-out;
      `
    );
  }}
`;

export const StyledCaption = styled.h1`
  margin: 0;
  color: ${black};
  padding-bottom: 10px;
  background: rgb(244, 244, 244);
`;
