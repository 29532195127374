import React from 'react';
import { history } from 'store/store';
import Modal from 'containers/Modal/Modal';
import { clearModal } from 'actions/modals/';
import { RoundButton } from 'components/Buttons/';
import { ITEM_EDIT_PATH } from 'constants/general';
import { ITEM_CREATED_MODAL } from 'constants/modal';
import { FlexCol, FlexRow } from 'components/Layout/';
import { useDispatch, useSelector } from 'react-redux';
import { StyledLabel } from 'containers/Modals/ItemCreatedModal/ItemCreatedModal.styled';

function ItemCreatedModal() {
  const dispatch = useDispatch();
  const modal = useSelector(({ modal }) => modal);
  const isOpen = modal?.type === ITEM_CREATED_MODAL;
  const item = isOpen && modal?.initialValue;
  const modalCaption = 'Item Successfully Created';

  /**
   * @param {string} payload
   */
  function onSubmit(payload) {
    const path = ITEM_EDIT_PATH.replace(':id', item.id);
    history.push(`${path}#${payload}`);
    dispatch(clearModal(ITEM_CREATED_MODAL));
  }

  return (
    <Modal
      modalTop={25}
      modalLeft={30}
      isOpen={isOpen}
      modalWidth={40}
      caption={modalCaption}
      onClose={onSubmit.bind(null, 'general')}
    >
      <>
        <FlexRow>
          <FlexCol flexWidth={12}>
            <StyledLabel>
              The next step is optional, either add condition or attributes to
              &nbsp;
              {item?.item_name}
            </StyledLabel>
          </FlexCol>
        </FlexRow>
        <FlexRow>
          <FlexCol flexWidth={4}>
            <RoundButton
              flexWidth={100}
              caption="Track by Condition"
              onClick={onSubmit.bind(null, 'condition')}
            />
          </FlexCol>
          <FlexCol flexWidth={4}>
            <RoundButton
              flexWidth={100}
              caption="Track by Attribute"
              onClick={onSubmit.bind(null, 'attributes')}
            />
          </FlexCol>
        </FlexRow>
      </>
    </Modal>
  );
}

export default ItemCreatedModal;
