import styled from 'styled-components';
import { maroon } from 'constants/color';

export const StyledErrorLabel = styled.label`
  color: ${maroon};
  font-size: 12px;
  font-weight: 400;
`;

export const StyledSubHeading = styled.h1`
  font-size: 12px;
  line-height: 18px;
  font-style: normal;
  font-weight: normal;
`;

export const StyledList = styled.ul`
  margin-top: 2px;
`;
export const StyledListItem = styled.li`
  color: #5d5d5d;
  font-size: 10px;
  margin-top: 0px;
  line-height: 12px;
  font-weight: normal;
  list-style-type: square;
  &:not(:first-child) {
    margin-top: 2px;
  }
`;

export const StyledStatus = styled.p`
  margin-top: -3px;
  font-size: 10px;
  font-style: normal;
  font-weight: normal;
  color: rgb(93, 93, 93);
`;
