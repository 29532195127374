import {
  GET_ITEMS,
  UPDATE_ITEM,
  CREATE_ITEM,
  DELETE_ITEM,
  UPDATE_ATTRIBUTE,
  CREATE_ATTRIBUTE,
  DELETE_ATTRIBUTE,
} from 'actions/types';
import { transformActions } from 'utils/';

let itemList = [];
const initialState = { data: [], isLoading: false };

/**
 *
 * @param {string} itemId
 * @param {[]} attributes
 * @returns {object | undefined}
 */
function getItems(itemId, attributes) {
  const item = itemList.find(function ({ id }) {
    return id === itemId;
  });
  const undeleted = attributes.filter(function (attr) {
    return !attr.is_deleted;
  });
  const detailed = undeleted.map(function (attribute) {
    const attr = item?.attributes.find(function ({ id }) {
      return id === attribute.id;
    });
    if (attr) {
      return { ...attr, ...attribute };
    }
    return attribute;
  });
  const data = itemList.map(function (item) {
    if (item.id === itemId) {
      return { ...item, attributes: [...detailed] };
    }
    return item;
  });
  itemList = data;
  return data;
}

/**
 * @description Processes and stores items array details in redux state.
 * @param {{}} state
 * @param {{}} action
 */
export function item(state = initialState, action) {
  const actions = [GET_ITEMS];
  const { type, payload } = action;
  const success = transformActions(actions, 'SUCCESS');
  const failure = transformActions(actions, 'FAILURE');
  if (type === `${CREATE_ITEM}_SUCCESS`) {
    const item = { ...payload, attributes: [] };
    itemList.push(item);
    return { data: itemList, isLoading: false };
  }
  if (type === `${DELETE_ITEM}_SUCCESS`) {
    const { id } = payload;
    const data = itemList.filter(function (item) {
      return item.id !== id;
    });
    itemList = data;
    return { data: itemList, isLoading: false };
  }
  if (type === `${CREATE_ATTRIBUTE}_SUCCESS`) {
    const created = payload.created_attributes || [];
    const updated = payload.updated_attributes || [];
    if (!created.length && !updated.length) {
      return { data: itemList, isLoading: false };
    }
    if (created.length && !updated.length) {
      const itemId = created[0]?.entity_id;
      const item = itemList.find(function ({ id }) {
        return id === itemId;
      });
      const attributes = item?.attributes.concat(created);
      const data = getItems(itemId, attributes);
      return { data, isLoading: false };
    }
    return { data: itemList, isLoading: false };
  }
  if (type === `${UPDATE_ATTRIBUTE}_SUCCESS`) {
    const { itemId, attributes } = payload;
    const [attribute] = attributes;
    const item = itemList.find(function ({ id }) {
      return id === itemId;
    });
    const attrs = item?.attributes.map(function (attr) {
      if (attr.id === attribute.id) {
        return { ...attr, ...attribute };
      }
      return attr;
    });
    const data = getItems(itemId, attrs);
    return { data, isLoading: false };
  }
  if (type === `${DELETE_ATTRIBUTE}_SUCCESS`) {
    const { itemId, names } = payload;
    const item = itemList.find(function ({ id }) {
      return id === itemId;
    });
    const attributes = item?.attributes.filter(function (attr) {
      return !names.includes(attr.attribute_name);
    });
    const data = getItems(itemId, attributes);
    return { data, isLoading: false };
  }
  if (type === `${UPDATE_ITEM}_SUCCESS`) {
    const { id, updatedValues } = payload;
    const data = itemList.map(function (item) {
      if (item.id === id) {
        return { ...item, ...updatedValues };
      }
      return item;
    });
    itemList = data;
    return { data: itemList, isLoading: false };
  }
  if (type === GET_ITEMS) {
    return { ...state, data: itemList, isLoading: true };
  }
  if (success.includes(type)) {
    itemList = payload;
    return { data: payload, isLoading: false };
  }
  if (failure.includes(type)) {
    return { ...state, isLoading: false };
  }
  return state;
}
