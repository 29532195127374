export const SALE = 'sale';
export const GOOD = 'good';
export const LOST = 'lost';
export const TASK = 'tasks';
export const LEASE = 'lease';
export const OTHER = 'Other';
export const EMAIL = 'email';
export const PRINT = 'print';
export const ORDER = 'orders';
export const PENDING = 'pending';
export const DURABLE = 'durable';
export const DAMAGED = 'damaged';
export const RETURNED = 'returned';
export const ASSIGNED = 'assigned';
export const ARCHIVED = 'archived';
export const INVENTORY = 'inventory';
export const CANCELLED = 'cancelled';
export const PERISHABLE = 'perishable';
export const NO_ATTRIBUTES = 'No Attributes';
