import {
  StyledTable,
  StyledTableData,
  StyledTableHead,
  StyledTableBody,
  StyledTableFoot,
} from 'components/Table/Table/Table.styled';
import {
  StyledTableHeader,
  StyledTableHeadRow,
} from 'components/Table/Header/Header.styled';
import { SelectInput } from 'components/Inputs/';
import { NO_ATTRIBUTES } from 'constants/inventory';
import {
  StyledUnit,
  StyledInput,
  StyledOption,
  StyledWrapper,
  StyledQuantity,
  StyledTableRow,
  StyledRoundIcon,
  StyledRowButton,
  StyledRoundButton,
  StyledErrorMessage,
  StyledDeleteButton,
  StyledSubTableData,
  StyledCurrencyInput,
  StyledQuantityWrapper,
} from 'components/Order/Catalogue/Catalogue.styled';
import React, { useMemo, useState, useCallback } from 'react';
import { ReactComponent as TrashIcon } from 'icons/delete.svg';

function Catalogue({
  good,
  type,
  total,
  message,
  statuses,
  currency,
  catalogue,
  warehouses,
  isReadOnly,
  messageRef,
  currencyRef,
  setCatalogue,
  emptyCatalogue,
  getItemDetails,
  includeLeasePrice,
  inventory: _items,
  getActiveGoodAttributes,
  getActiveItemAttributes,
}) {
  const error = { name: '', itemId: '', attribute: '', quantity: '' };
  const [errors, setErrors] = useState(error);

  const currencyOptions = useMemo(() => {
    if (currency && typeof currency === 'string') {
      return [currency];
    }
    if (currency && currency.length > 0) {
      return currency;
    }
    return [];
  }, [currency]);

  const inventory = useMemo(() => {
    const data = _items.map(function (item) {
      let attrs = [];
      let condition = {};
      const { conditions, attributes } = item;
      if (conditions) {
        const obj = conditions.conditions;
        const entries = Object.entries(obj).map(function ([key, value]) {
          const status = statuses.find(({ id }) => id === key);
          return [status ? status.status_name : key, value];
        });
        condition = Object.fromEntries(entries);
        const data = { id: good?.id, name: 'Good', quantity: obj[good?.id] };
        attrs = [data];
      } else if (attributes && attributes.length > 0) {
        attrs = groupAttributes(attributes);
        const active = getActiveGoodAttributes(attributes);
        condition = { good: sumQuantity(active) };
      } else {
        condition = { good: item.quantity };
      }
      return { ...item, attrs, ...condition };
    });
    return data;
  }, [_items, statuses]);

  const totalItems = useMemo(() => {
    return sumQuantity(catalogue);
  }, [catalogue]);

  const itemOptions = useMemo(() => {
    if (inventory && inventory.length > 0) {
      let items = inventory;
      if (type) {
        items = inventory.filter(function (item) {
          return item.item_order_type === type;
        });
      }
      if (warehouses && warehouses.length > 0) {
        items = items.filter(function (item) {
          return warehouses.includes(item.warehouse_id);
        });
      }
      const catIds = catalogue.map(function ({ itemId }) {
        return itemId;
      });
      const filtered = items.filter(function ({ id, quantity }) {
        return !catIds.includes(id) && quantity > 0;
      });
      const options = filtered.map(function (item) {
        const {
          id,
          attrs,
          quantity,
          item_name: name,
          quantity_unit: unit,
        } = item;
        const total = attrs.length > 0 ? sumQuantity(attrs) : quantity;
        const label = `${name} (${total} ${unit})`;
        return { label, value: id };
      });
      return options;
    }
    return [];
  }, [type, catalogue, inventory, warehouses]);

  function sumQuantity(array) {
    const sum = array.reduce(function (acc, { quantity }) {
      return acc + quantity;
    }, 0);
    return sum;
  }

  /**
   * @param {{
   * label: string
   * value: string
   * }} option
   */
  function onAttributeChange(option) {
    if (option) {
      const { value } = option;
      const { name, itemId } = JSON.parse(value);
      const noAttributeCount = getNonAttributedItems(itemId);
      const attributeCount = getActiveItemAttributes(itemId, name).length;
      const stock = name === NO_ATTRIBUTES ? noAttributeCount : attributeCount;
      const nextCat = catalogue.map(function (row) {
        if (row.itemId === itemId) {
          const attributes = [...row.attributes, { name, stock, quantity: 1 }];
          return { ...row, attributes, quantity: sumQuantity(attributes) };
        }
        return row;
      });
      setCatalogue(nextCat);
    }
  }

  /**
   * @param {{
   * label: string
   * value: string
   * }} option
   * @param {{ name: string }} input
   */
  function onItemChange(option, input) {
    if (option) {
      const { value } = option;
      const { unit, name, price, stock } = getItemDetails(value);
      const nextCat = catalogue.map(function (row) {
        if (row.itemId === input.name) {
          return {
            ...row,
            name,
            unit,
            price,
            stock,
            quantity: 1,
            itemId: value,
            attributes: [],
          };
        }
        return row;
      });
      setCatalogue(nextCat);
    }
  }

  /**
   * @param {[]} attributes
   */
  function groupAttributes(attributes) {
    const active = getActiveGoodAttributes(attributes);
    const grouped = active.reduce(function (acc, attribute) {
      const { id, quantity, attribute_name: name } = attribute;
      if (!acc[name]) {
        acc[name] = { quantity: 0 };
      }
      const cumulative = acc[name].quantity + 1;
      const count = quantity > 1 ? quantity : cumulative;
      acc[name] = { id, name, quantity: count };
      return acc;
    }, {});
    return Object.values(grouped);
  }

  /**
   * @param {string} itemId
   */
  function getItemAttributesOptions(itemId) {
    const item = inventory.find(function ({ id }) {
      return id === itemId;
    });
    const catItems = catalogue.filter(function (row) {
      return row.itemId === itemId;
    });
    const goodAttrs = item ? item.good : 0;
    const quantity = item ? item.quantity : 0;
    const damagedAttrs = item ? item.damaged : 0;
    const attributed = goodAttrs + damagedAttrs;
    const attributes = item ? item.attributes : [];
    const conditions = item ? item.conditions : undefined;
    if (conditions || !attributes.length) {
      if (catItems && catItems[0].attributes.length > 0) {
        return [];
      }
      const value = JSON.stringify({ itemId, name: NO_ATTRIBUTES });
      const options = [{ label: `${NO_ATTRIBUTES} - ${goodAttrs}`, value }];
      return options;
    }
    const active = attributes.filter(function ({ quantity }) {
      return quantity > 0;
    });
    const filtered = active.filter(function (attr) {
      return !catItems.some(function ({ attributes }) {
        return attributes.some(function ({ name }) {
          return name === attr.attribute_name;
        });
      });
    });
    const grouped = groupAttributes(filtered);
    const options = grouped.map(function (item) {
      const { name, quantity } = item;
      const label = `${name} - ${quantity}`;
      const value = JSON.stringify({ name, itemId });
      return { label, value };
    });
    const unknown = quantity - attributed;
    const hasNoAttribute = catItems.find(function ({ attributes }) {
      return attributes.some(function ({ name }) {
        return name === NO_ATTRIBUTES;
      });
    });
    if (unknown > 0 && !hasNoAttribute) {
      const value = JSON.stringify({ itemId, name: NO_ATTRIBUTES });
      options.push({ label: `${NO_ATTRIBUTES} - ${unknown}`, value });
    }
    return options;
  }

  /**
   * @param {string} itemId
   */
  function getNonAttributedItems(itemId) {
    const item = inventory.find(function ({ id }) {
      return id === itemId;
    });
    const { conditions, attributes } = item;
    if (conditions) {
      return item.good;
    }
    if (!conditions && !attributes.length) {
      return item.quantity;
    }
    return 0; // todo: fix this
  }

  /**
   * @param {string} sign
   * @param {string} name
   * @param {string} itemId
   */
  function onAttributeQuantityChange(itemId, name, sign) {
    const errorObject = { itemId, name: '', quantity: '' };
    if (sign === '+') {
      let isValid = true;
      const nextRows = catalogue.map(function (row) {
        if (row.itemId === itemId) {
          const { attributes } = row;
          const nextAttributes = attributes.map(function (attr) {
            if (attr.name === name) {
              const quantity = attr.quantity + 1;
              if (quantity > attr.stock) {
                isValid = false;
                errorObject.quantity = 'Quantity exceed current stock';
                return attr;
              } else {
                errorObject.quantity = '';
              }
              return { ...attr, quantity };
            }
            return attr;
          });
          const total = sumQuantity(nextAttributes);
          return { ...row, quantity: total, attributes: nextAttributes };
        }
        return row;
      });
      setErrors(errorObject);
      if (isValid) {
        setCatalogue(nextRows);
      }
    } else {
      setErrors(errorObject);
      const nextCat = catalogue.map(function (row) {
        if (row.itemId === itemId) {
          const { attributes } = row;
          const nextAttributes = attributes.map(function (attr) {
            if (attr.name === name) {
              if (attr.quantity - 1 <= 0) {
                return attr;
              }
              return { ...attr, quantity: attr.quantity - 1 };
            }
            return attr;
          });
          if (row.quantity - 1 < attributes.length) {
            return row;
          }
          return {
            ...row,
            quantity: row.quantity - 1,
            attributes: nextAttributes,
          };
        }
        return row;
      });
      setCatalogue(nextCat);
    }
  }

  /**
   * Validates the last row before adding a new one.
   */
  const isFormValid = useCallback(() => {
    const errorObject = {};
    const { length } = catalogue;
    // grab either the first row or the last row
    const row = length === 1 ? catalogue[0] : catalogue[length - 1];
    const { name, itemId, quantity } = row;
    if (!name) {
      errorObject.name = 'Item name is required';
    } else {
      errorObject.name = '';
    }
    if (!quantity) {
      errorObject.quantity = 'Quantity is required';
    } else {
      errorObject.quantity = '';
    }
    const hasError = Object.values(errorObject).some(function (value) {
      return value.length > 0;
    });
    if (hasError) {
      setErrors({ ...errorObject, itemId });
      return false;
    }
    setErrors(error);
    return true;
  }, [catalogue, errors, onAddItem]);

  function onAddItem() {
    if (isFormValid()) {
      setCatalogue([...catalogue, { ...emptyCatalogue, itemId: '' }]);
    }
  }

  /**
   * @param {string} itemId
   * @param {string} attributeName
   */
  function onRemoveItem(itemId, attributeName) {
    if (attributeName) {
      const nextCat = catalogue.map(function (row) {
        if (row.itemId === itemId) {
          const attributes = row.attributes.filter(function ({ name }) {
            return name !== attributeName;
          });
          const quantity = sumQuantity(attributes);
          return { ...row, quantity, attributes };
        }
        return row;
      });
      setCatalogue(nextCat);
    } else {
      const nextCat = catalogue.filter(function (row) {
        return row.itemId !== itemId;
      });
      setCatalogue(nextCat);
    }
  }

  /**
   * @param {{
   * currentTarget: {
   * name: string
   * value: string
   * }
   * }} event
   */
  function onInputChange(event) {
    const { currentTarget } = event;
    const { id, name, value } = currentTarget;
    const [, itemId, attrName] = id.split('_');
    if (name === 'attribute-quantity') {
      const newQuantity = +value;
      const nextCat = catalogue.map(function (row) {
        if (row.itemId === itemId) {
          const attributes = row.attributes.map(function (attr) {
            if (attr.name === attrName) {
              if (newQuantity > attr.stock) {
                return attr;
              }
              return { ...attr, quantity: newQuantity };
            }
            return attr;
          });
          const quantity = sumQuantity(attributes);
          return { ...row, quantity, attributes };
        }
        return row;
      });
      setCatalogue(nextCat);
    } else {
      const nextCat = catalogue.map(function (row) {
        if (row.itemId === itemId) {
          return { ...row, [name]: +value };
        }
        return row;
      });
      setCatalogue(nextCat);
    }
  }

  return (
    <StyledWrapper>
      {message ? (
        <StyledErrorMessage $main aria-live="polite" ref={messageRef}>
          {message}
        </StyledErrorMessage>
      ) : null}
      <StyledTable>
        <StyledTableHead>
          <StyledTableHeadRow>
            <StyledTableHeader>Product Name</StyledTableHeader>
            <StyledTableHeader>Attribute</StyledTableHeader>
            <StyledTableHeader>Quantity</StyledTableHeader>
            {!includeLeasePrice ? null : (
              <>
                <StyledTableHeader>Item Price</StyledTableHeader>
                <StyledTableHeader>Total Price</StyledTableHeader>
              </>
            )}
            <StyledTableHeader></StyledTableHeader>
          </StyledTableHeadRow>
        </StyledTableHead>
        <StyledTableBody>
          {catalogue.map(function (row) {
            const {
              name,
              unit,
              price,
              itemId,
              quantity,
              attributes = [],
            } = row;
            const hasItemId = !!itemId;
            const total = price * quantity;
            const inputId = `name_${itemId}`;
            const hasError = errors.itemId === itemId;
            const showAttributes = attributes.length > 0;
            const option = { label: name, value: itemId };
            const attrOptions = getItemAttributesOptions(itemId);
            const showAttributesInput = hasItemId && attrOptions?.length > 0;
            return (
              <>
                <StyledTableRow key={itemId}>
                  <StyledTableData $mini>
                    <SelectInput
                      name={itemId}
                      value={option}
                      isLoading={false}
                      isClearable={true}
                      isBorderLess={true}
                      isSearchable={true}
                      disabled={isReadOnly}
                      options={itemOptions}
                      onChange={onItemChange}
                      ariaLabelledBy={inputId}
                      placeholder="Select item"
                      errorMessage={errors.item}
                      caption={`Item Name-${name}`}
                      menuPortalTarget={window.document.body}
                    />
                    {hasError ? (
                      <StyledErrorMessage aria-live="polite">
                        {errors.name}
                      </StyledErrorMessage>
                    ) : null}
                  </StyledTableData>
                  <StyledTableData $mini>
                    {showAttributesInput ? (
                      <SelectInput
                        value=""
                        name={itemId}
                        isLoading={false}
                        isClearable={true}
                        isBorderLess={true}
                        isSearchable={true}
                        disabled={isReadOnly}
                        options={attrOptions}
                        onChange={onAttributeChange}
                        placeholder="Select attribute"
                        errorMessage={errors.attribute}
                        caption={`Item Attribute-${name}`}
                        ariaLabelledBy={`attribute_${itemId}`}
                        menuPortalTarget={window.document.body}
                      />
                    ) : null}
                  </StyledTableData>
                  <StyledTableData $mini aria-disabled={isReadOnly}>
                    <StyledQuantityWrapper style={{ marginLeft: '15px' }}>
                      <StyledInput
                        type="number"
                        name="quantity"
                        value={quantity}
                        placeholder="qty"
                        onChange={onInputChange}
                        id={`quantity_${itemId}`}
                        data-testid={`quantity_${itemId}`}
                        disabled={isReadOnly || showAttributesInput}
                      />
                      <StyledUnit>{unit}</StyledUnit>
                    </StyledQuantityWrapper>
                    {hasError ? (
                      <StyledErrorMessage aria-live="polite">
                        {errors.quantity}
                      </StyledErrorMessage>
                    ) : null}
                  </StyledTableData>
                  {!includeLeasePrice ? null : (
                    <>
                      <StyledTableData $mini aria-disabled={isReadOnly}>
                        {Number.isNaN(price) ? 0 : price.toLocaleString()}
                      </StyledTableData>
                      <StyledTableData $mini aria-disabled={isReadOnly}>
                        {Number.isNaN(total) ? 0 : total.toLocaleString()}
                      </StyledTableData>
                    </>
                  )}
                  <StyledTableData $mini>
                    {isReadOnly || catalogue.length === 1 || !name ? null : (
                      <StyledDeleteButton
                        data-testid={`delete-button-${itemId}`}
                        title={`Remove ${name} from catalogue`}
                        onClick={onRemoveItem.bind(null, itemId, null)}
                      >
                        <TrashIcon />
                      </StyledDeleteButton>
                    )}
                  </StyledTableData>
                </StyledTableRow>
                {showAttributes
                  ? attributes.map(function (attr) {
                      const {
                        stock,
                        name: attrName,
                        quantity: attrQuantity,
                      } = attr;
                      const attrId = `${itemId}_${attrName}`;
                      const isLastRow =
                        attrName === attributes[attributes.length - 1].name;
                      if (!attrName) {
                        return null;
                      }
                      return (
                        <StyledTableRow $sub={true} key={attrId}>
                          <StyledSubTableData />
                          <StyledSubTableData
                            $last={isLastRow}
                            aria-disabled={isReadOnly}
                          >
                            {attrName} - {stock}
                          </StyledSubTableData>
                          <StyledSubTableData
                            $last={isLastRow}
                            style={{ width: '20%' }}
                          >
                            <StyledQuantity aria-disabled={isReadOnly}>
                              {hasItemId ? (
                                <StyledQuantityWrapper>
                                  {isReadOnly ? null : (
                                    <StyledRoundButton
                                      $minus
                                      onClick={onAttributeQuantityChange.bind(
                                        null,
                                        itemId,
                                        attrName,
                                        '-',
                                      )}
                                      data-testid={`attribute-minus-button-${attrId}`}
                                    >
                                      -
                                    </StyledRoundButton>
                                  )}
                                  <StyledInput
                                    type="number"
                                    placeholder="qty"
                                    value={attrQuantity}
                                    disabled={isReadOnly}
                                    onChange={onInputChange}
                                    name="attribute-quantity"
                                    id={`attribute-quantity_${attrId}`}
                                    data-testid={`attribute-quantity_${attrId}`}
                                  />
                                  {isReadOnly ? null : (
                                    <StyledRoundButton
                                      onClick={onAttributeQuantityChange.bind(
                                        null,
                                        itemId,
                                        attrName,
                                        '+',
                                      )}
                                      data-testid={`attribute-add-button-${attrId}`}
                                    >
                                      +
                                    </StyledRoundButton>
                                  )}
                                </StyledQuantityWrapper>
                              ) : null}
                            </StyledQuantity>
                          </StyledSubTableData>
                          {includeLeasePrice ? (
                            <>
                              <StyledSubTableData $last={isLastRow} />
                              <StyledSubTableData $last={isLastRow} />
                            </>
                          ) : null}
                          <StyledSubTableData $last={isLastRow}>
                            {isReadOnly ? null : (
                              <StyledDeleteButton
                                onClick={onRemoveItem.bind(
                                  null,
                                  itemId,
                                  attrName,
                                )}
                                data-testid={`delete-button-${attrId}`}
                                title={`Remove ${attrName} from catalogue`}
                              >
                                <TrashIcon />
                              </StyledDeleteButton>
                            )}
                          </StyledSubTableData>
                        </StyledTableRow>
                      );
                    })
                  : null}
              </>
            );
          })}
        </StyledTableBody>
        <StyledTableFoot>
          <StyledTableRow>
            <StyledTableData $mini>
              {isReadOnly ? null : (
                <StyledRowButton onClick={onAddItem}>
                  <StyledRoundIcon>+</StyledRoundIcon>
                  Add Item
                </StyledRowButton>
              )}
            </StyledTableData>
            <StyledTableData $mini></StyledTableData>
            <StyledTableData $mini aria-disabled={isReadOnly}>
              Total Items: {totalItems}
            </StyledTableData>
            <StyledTableData $mini></StyledTableData>
            {includeLeasePrice ? (
              <>
                <StyledTableData $mini aria-disabled={isReadOnly}>
                  Total:
                  <StyledCurrencyInput ref={currencyRef} disabled={isReadOnly}>
                    {currencyOptions.map(function (value) {
                      return <StyledOption key={value}>{value}</StyledOption>;
                    })}
                  </StyledCurrencyInput>
                  {isNaN(total) ? 0 : total.toLocaleString()}
                </StyledTableData>
                <StyledTableData $mini></StyledTableData>
              </>
            ) : null}
          </StyledTableRow>
        </StyledTableFoot>
      </StyledTable>
    </StyledWrapper>
  );
}

export default Catalogue;
