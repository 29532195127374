import {
  GET_CONDITION,
  CREATE_CONDITION,
  UPDATE_CONDITION,
  DELETE_CONDITION,
} from 'actions/types';

let conditionList = [];
const initialState = { data: [], isLoading: false };

/**
 * @description Stores condition array details in redux state.
 * @returns {[]}
 * @param {[]} state
 * @param {{}} action
 */
export function condition(state = initialState, action) {
  const { type, payload } = action;
  if (type === `${GET_CONDITION}_FAILURE`) {
    return { ...state, isLoading: false };
  }
  if (type === `${GET_CONDITION}_SUCCESS`) {
    conditionList = payload;
    return { isLoading: false, data: conditionList };
  }
  if (type === `${CREATE_CONDITION}_SUCCESS`) {
    conditionList = [...conditionList, payload];
    return { isLoading: false, data: conditionList };
  }
  if (type === `${UPDATE_CONDITION}_SUCCESS`) {
    const { id, updatedValues } = payload;
    const data = conditionList.map(function (condition) {
      if (condition.id === id) {
        return { ...condition, ...updatedValues };
      }
      return condition;
    });
    conditionList = data;
    return { isLoading: false, data: conditionList };
  }
  if (type === `${DELETE_CONDITION}_SUCCESS`) {
    conditionList = [];
    return { isLoading: false, data: conditionList };
  }
  return state;
}
