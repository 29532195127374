// global
export const CANCEL_API_REQUEST = 'CANCEL_API_REQUEST';
// user
export const GET_USER = 'GET_USER';
export const GET_USERS = 'GET_USERS';
export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const LOG_OUT_USER = 'LOG_OUT_USER';
export const AUTHENTICATE_USER = 'AUTHENTICATE_USER';
export const SET_LOGGED_IN_USER = 'SET_LOGGED_IN_USER';
export const GET_USER_FROM_SESSION = 'GET_USER_FROM_SESSION';
// modals
export const SHOW_MODAL = 'SHOW_MODAL';
export const CLEAR_MODAL = 'CLEAR_MODAL';
// notify
export const SHOW_NOTIFY = 'SHOW_NOTIFY';
export const CLEAR_NOTIFY = 'CLEAR_NOTIFY';
// menus
export const SET_ACTIVE_MENU = 'SET_ACTIVE_MENU';
export const SET_ACTIVE_TOP_MENU = 'SET_ACTIVE_TOP_MENU';
// onboarding
export const ONBOARDING_PROGRESS = 'ONBOARDING_PROGRESS';
// theme
export const SET_THEME_STYLES = 'SET_THEME_STYLES';
// customers
export const GET_CUSTOMERS = 'GET_CUSTOMERS';
export const CREATE_CUSTOMER = 'CREATE_CUSTOMER';
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER';
// tasks
export const GET_TASKS = 'GET_TASKS';
export const CREATE_TASK = 'CREATE_TASK';
export const UPDATE_TASK = 'UPDATE_TASK';
export const DELETE_TASK = 'DELETE_TASK';
// warehouses
export const GET_WAREHOUSES = 'GET_WAREHOUSES';
export const CREATE_WAREHOUSE = 'CREATE_WAREHOUSE';
export const UPDATE_WAREHOUSE = 'UPDATE_WAREHOUSE';
export const DELETE_WAREHOUSE = 'DELETE_WAREHOUSE';
export const GET_WAREHOUSE_USAGE = 'GET_WAREHOUSE_USAGE';
// categories
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
// tags
export const GET_TAGS = 'GET_TAGS';
export const CREATE_TAG = 'CREATE_TAG';
export const UPDATE_TAG = 'UPDATE_TAG';
export const DELETE_TAG = 'DELETE_TAG';
// condition
export const GET_CONDITION = 'GET_CONDITION';
export const CREATE_CONDITION = 'CREATE_CONDITION';
export const UPDATE_CONDITION = 'UPDATE_CONDITION';
export const DELETE_CONDITION = 'DELETE_CONDITION';
// items
export const GET_ITEMS = 'GET_ITEMS';
export const CREATE_ITEM = 'CREATE_ITEM';
export const UPDATE_ITEM = 'UPDATE_ITEM';
export const UPLOAD_ITEM = 'UPLOAD_ITEM';
export const DELETE_ITEM = 'DELETE_ITEM';
export const GET_STOCK_MOVE = 'GET_STOCK_MOVE';
export const GET_ITEM_UPLOAD = 'GET_ITEM_UPLOAD';
export const UPDATE_BULK_ITEM = 'UPDATE_BULK_ITEM';
export const GET_ITEM_HISTORY = 'GET_ITEM_HISTORY';
export const GET_STOCK_CONDITION = 'GET_STOCK_CONDITION';
// peoples
export const GET_PEOPLES = 'GET_PEOPLES';
export const CREATE_PEOPLE = 'CREATE_PEOPLE';
export const UPDATE_PEOPLE = 'UPDATE_PEOPLE';
export const DELETE_PEOPLE = 'DELETE_PEOPLE';
// orders
export const GET_ORDERS = 'GET_ORDERS';
export const PRINT_ORDER = 'PRINT_ORDER';
export const EMAIL_ORDER = 'EMAIL_ORDER';
export const CREATE_ORDER = 'CREATE_ORDER';
export const RETURN_ORDER = 'RETURN_ORDER';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const DELETE_ORDER = 'DELETE_ORDER';
export const ASSIGN_ORDER = 'ASSIGN_ORDER';
export const UPDATE_BULK_ORDER = 'UPDATE_BULK_ORDER';
export const GET_ORDER_HISTORY = 'GET_ORDER_HISTORY';
export const GET_ASSIGNED_ORDERS = 'GET_ASSIGNED_ORDERS';
export const UPDATE_ASSIGNED_ORDER = 'UPDATE_ASSIGNED_ORDER';
// companies
export const GET_COMPANY = 'GET_COMPANY';
export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const CREATE_COMPANY = 'CREATE_COMPANY';
// logs
export const GET_LOGS = 'GET_LOGS';
// assets
export const GET_ASSETS = 'GET_ASSETS';
export const CREATE_ASSET = 'CREATE_ASSET';
export const UPDATE_ASSET = 'UPDATE_ASSET';
export const DELETE_ASSET = 'DELETE_ASSET';
export const ASSIGN_ASSET = 'ASSIGN_ASSET';
export const GET_ASSIGNED_ASSETS = 'GET_ASSIGNED_ASSETS';
export const UPDATE_ASSIGNED_ASSET = 'UPDATE_ASSIGNED_ASSET';
export const DELETE_ASSIGNED_ASSET = 'DELETE_ASSIGNED_ASSET';
// storage
export const GET_STORAGES = 'GET_STORAGES';
export const CREATE_STORAGE = 'CREATE_STORAGE';
export const UPDATE_STORAGE = 'UPDATE_STORAGE';
export const DELETE_STORAGE = 'DELETE_STORAGE';
// account
export const CREATE_ADMIN = 'CREATE_ADMIN';
export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';
// password reset
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
// notifications
export const GET_NOTIFICATION = 'GET_NOTIFICATION';
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
// attribute
export const GET_ATTRIBUTE = 'GET_ATTRIBUTE';
export const CREATE_ATTRIBUTE = 'CREATE_ATTRIBUTE';
export const UPDATE_ATTRIBUTE = 'UPDATE_ATTRIBUTE';
export const DELETE_ATTRIBUTE = 'DELETE_ATTRIBUTE';
// status
export const GET_DEFAULT_STATUSES = 'GET_DEFAULT_STATUSES';
