import { GET_TAGS, CREATE_TAG, UPDATE_TAG, DELETE_TAG } from 'actions/types';

let tagList = [];
const initialState = { data: [], isLoading: false };

/**
 * @description Stores tags array details in redux state.
 * @returns {[]}
 * @param {[]} state
 * @param {{}} action
 */
export function tag(state = initialState, action) {
  const { type, payload } = action;
  if (type === `${GET_TAGS}_FAILURE`) {
    return { ...state, isLoading: false };
  }
  if (type === `${GET_TAGS}_SUCCESS`) {
    tagList = payload;
    return { data: tagList, isLoading: false };
  }
  if (type === `${CREATE_TAG}_SUCCESS`) {
    tagList = [...tagList, payload];
    return { data: tagList, isLoading: false };
  }
  if (type === `${UPDATE_TAG}_SUCCESS`) {
    const { id, updatedValues } = payload;
    const data = tagList.map(function (tag) {
      if (tag.id === id) {
        return { ...tag, ...updatedValues };
      }
      return tag;
    });
    tagList = data;
    return { data: tagList, isLoading: false };
  }
  if (type === `${DELETE_TAG}_SUCCESS`) {
    const { id } = payload;
    const data = tagList.filter(function (tag) {
      return tag.id !== id;
    });
    tagList = data;
    return { data: tagList, isLoading: false };
  }
  return state;
}

export default tag;
