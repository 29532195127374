import React, {
  useRef,
  useMemo,
  useState,
  Fragment,
  useEffect,
  createRef,
} from 'react';
import {
  getOrderNumber,
  objectHasProperty,
  capitalizeFirstLetter,
} from 'utils/';
import { history } from 'store/store';
import { GOOD } from 'constants/inventory';
import {
  StyledTable,
  StyledTableBody,
  StyledTableHead,
} from 'components/Table/Table/Table.styled';
import { RETURN_ORDER } from 'actions/types';
import { Loading } from 'components/Loaders/';
import {
  StyledTableHeader,
  StyledTableHeadRow,
} from 'components/Table/Header/Header.styled';
import {
  StyledArrow,
  StyledInput,
  StyledHeader,
  StyledSelect,
  StyledOption,
  StyledMessage,
  StyledWrapper,
  StyledTableRow,
  StyledTableData,
  StyledButtonRow,
  StyledSrOnlyLabel,
  StyledButtonWrapper,
  StyledWareHouseInputWrapper,
} from 'components/Order/Return/Return.styled';
import { invalid, primary } from 'constants/color';
import { EDIT_ORDER_PATH } from 'constants/general';
import { GENERATE_SKU_MODAL } from 'constants/modal';
import { CheckBox, SelectInput } from 'components/Inputs/';
import { ReactComponent as TickIcon } from 'icons/tick.svg';
import { RoundButton, CancelButton } from 'components/Buttons/';
import { ReactComponent as ChevronIcon } from 'icons/chevron.svg';
import { useMobileView, useHttpStatus, useSelectOption } from 'hooks/';

function Return({
  order,
  onSubmit,
  warehouses,
  defaultStatus,
  onShowSKUModal,
}) {
  const { id, items: catalogue = [], order_type: type = '' } = order;
  const inputRefs = useRef({});
  const { response } = useHttpStatus({
    actions: [RETURN_ORDER],
  });
  const isMobileView = useMobileView();
  const statusOptions = useSelectOption({
    capitalize: true,
    items: defaultStatus,
    label: 'status_name',
  });
  const good = useMemo(() => {
    const status = defaultStatus.find(function (status) {
      return status.status_name === GOOD;
    });
    return status ? status.id : '';
  }, [defaultStatus]);

  const marginRight = isMobileView ? 5 : 20;
  const [message, setMessage] = useState('');
  const [checked, setChecked] = useState(false);
  const [items, setItems] = useState(catalogue);
  const hasOrder = objectHasProperty(order, 'id');
  const [checkedIds, setCheckedIds] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const orderNumber = getOrderNumber({ id, prefix: '#' });
  const warehouseOptions = useSelectOption({ items: warehouses });

  const allIds = useMemo(() => {
    if (items && items.length > 0) {
      const ids = items.flatMap(function (item) {
        const { id } = item;
        const itemIds = [item.id];
        if (item.attributes && item.attributes.length) {
          const attributeIds = item.attributes.map(function (attr) {
            return `${attr.id}|${id}`;
          });
          return itemIds.concat(attributeIds);
        }
        if (item.conditions && item.conditions.length) {
          const conditionIds = item.conditions.map(function (status) {
            return status.id;
          });
          return itemIds.concat(conditionIds);
        }
        return itemIds;
      });
      return ids;
    }
    return [];
  }, [items, checkedIds]);

  /**
   * @param {string} id
   */
  function onToggleExpandRow(id) {
    setExpandedRows(function (prev) {
      return prev.includes(id)
        ? prev.filter(function (item) {
            return item !== id;
          })
        : [...prev, id];
    });
  }

  function onConditionChange(event) {
    const { id, value } = event.target;
    setItems(function (prev) {
      return prev.map(function (item) {
        if (item.id === id) {
          return { ...item, condition: value };
        }
        if (item.attributes && item.attributes.length) {
          const attributes = item.attributes.map(function (attr) {
            if (String(attr.id) === id) {
              return { ...attr, condition: value };
            }
            return attr;
          });
          return { ...item, attributes };
        }
        return item;
      });
    });
  }

  function onWarehouseChange(id, { value, label }) {
    setItems(function (prev) {
      return prev.map(function (item) {
        if (item.id === id) {
          const { sku, name } = item;
          if (value !== item.sourceWareHouse && !sku) {
            onShowSKUModal({ id, name });
          }
          return { ...item, warehouse_id: value, wareHouse: { value, label } };
        }
        return item;
      });
    });
  }

  /**
   * @param {string} id
   */
  function onCheckChange(id) {
    if (id === 'all') {
      setCheckedIds(function (prev) {
        const allItemIds = items.flatMap(function (item) {
          const { id, attributes = [], conditions = [] } = item;
          const attributeIds = attributes.map(({ id }) => id);
          const conditionIds = conditions.map(({ id }) => id);
          return [id, ...attributeIds, ...conditionIds];
        });
        return prev.length === allItemIds.length ? [] : allItemIds;
      });
    } else {
      onToggleExpandRow(id);
      const item = items.find(function (item) {
        return item.id === id;
      });
      if (item && item.attributes && item.attributes.length) {
        const attrIds = item.attributes.map(function (attr) {
          return attr.id;
        });
        const ids = [id, ...attrIds];
        setCheckedIds(function (prev) {
          if (prev.includes(id)) {
            return prev.filter(function (item) {
              return !ids.includes(item);
            });
          } else {
            return [...prev, ...ids];
          }
        });
      } else {
        setCheckedIds(function (prev) {
          if (prev.includes(id)) {
            return prev.filter(function (item) {
              return item !== id;
            });
          } else {
            return [...prev, id];
          }
        });
      }
    }
  }

  /**
   * @param {string} id
   */
  function selectInput(id) {
    return (
      <>
        <StyledSrOnlyLabel htmlFor={id}>condition-{id}</StyledSrOnlyLabel>
        <StyledSelect id={id} onChange={onConditionChange}>
          {statusOptions.map(function ({ value, label }) {
            return (
              <StyledOption key={value} value={value}>
                {label}
              </StyledOption>
            );
          })}
        </StyledSelect>
      </>
    );
  }

  /**
   * @param {{
   * value: string,
   * label: string,
   * }} wareHouse
   * @param {string} id
   */
  function warehouseInput(id, wareHouse) {
    const inputId = `warehouse-${id}`;
    return (
      <StyledWareHouseInputWrapper>
        <SelectInput
          name={inputId}
          marginBottom={5}
          isLoading={false}
          caption={inputId}
          value={wareHouse}
          isClearable={true}
          isBorderLess={true}
          isSearchable={true}
          ariaLabelledBy={inputId}
          options={warehouseOptions}
          placeholder="Select warehouse"
          menuPortalTarget={window.document.body}
          onChange={onWarehouseChange.bind(null, id)}
        />
      </StyledWareHouseInputWrapper>
    );
  }

  function sumReturned(items) {
    return items.reduce((sum, i) => sum + (i.returned || 0), 0);
  }

  function calculateTotalReturned(item) {
    if (item.attributes && item.attributes.length) {
      return sumReturned(item.attributes);
    } else if (item.conditions && item.conditions.length) {
      return sumReturned(item.conditions);
    }
    return 0;
  }

  function onQuantityChange(data, event) {
    const { value } = event.target;
    const { id, attrId, quantity } = JSON.parse(data);
    const inputId = attrId ? attrId : id;
    const inputRef = inputRefs.current[inputId];
    const exceed = 'Returned quantity is greater than original quantity';
    const returned = !value || Number.isNaN(value) ? '' : parseInt(value, 10);
    const totalReturned = items.reduce(function (acc, item) {
      return item.id === id ? acc + calculateTotalReturned(item) : acc;
    }, 0);
    if (totalReturned - (attrId ? 0 : returned) + returned > quantity) {
      setMessage(exceed);
      if (inputRef && inputRef.current) {
        inputRef.current.select();
        inputRef.current.style.borderColor = invalid;
      }
      return;
    } else {
      setMessage('');
      if (inputRef && inputRef.current) {
        inputRef.current.style.borderColor = primary;
      }
    }
    setItems(function (prev) {
      return prev.map(function (item) {
        if (item.id === id) {
          if (item.attributes && item.attributes.length) {
            const attributes = item.attributes.map(function (attr) {
              if (attr.id === attrId) {
                return { ...attr, returned };
              }
              return attr;
            });
            return { ...item, attributes };
          } else {
            const conditions = item.conditions.map(function (status) {
              if (status.id === attrId) {
                return { ...status, returned };
              }
              return status;
            });
            return { ...item, conditions };
          }
        }
        return item;
      });
    });
  }

  function onCancel() {
    const path = EDIT_ORDER_PATH.replace(':id', id);
    history.push(path);
  }

  function onReturnOrder() {
    const filtered = items.filter(function (item) {
      const { id, attributes = [] } = item;
      const attrs = attributes.filter(function (attr) {
        return checkedIds.includes(attr.id);
      });
      return checkedIds.includes(id) || attrs.length > 0;
    });
    if (filtered.length > 0) {
      setMessage('');
      const items = filtered.map(function (item) {
        const condition = {};
        const {
          id,
          returned,
          conditions,
          attributes = [],
          warehouse_id: warehouseId,
        } = item;
        const props = {};
        conditions.forEach(function (status) {
          condition[status.id.split('|')[0]] = status.returned;
        });
        const returnedAttributes = attributes.filter(function (attr) {
          return checkedIds.includes(attr.id);
        });
        if (returnedAttributes.length > 0) {
          const attributes = returnedAttributes.map(function (attr) {
            const { id, returned, condition } = attr;
            return {
              id,
              quantity: returned,
              condition: condition ? condition : good,
            };
          });
          props.attributes = attributes;
        } else {
          props.condition = condition;
        }
        return { id, ...props, quantity: returned, warehouse_id: warehouseId };
      });
      onSubmit({ id, items });
    } else {
      setMessage('Please select at least one item to return');
    }
  }

  useEffect(() => {
    if (response && response.id) {
      if (response.source !== GENERATE_SKU_MODAL) {
        onCancel();
      }
    }
  }, [response]);

  useEffect(() => {
    allIds.forEach(function (id) {
      inputRefs.current[id] = inputRefs.current[id] || createRef();
    });
  }, [allIds]);

  useEffect(() => {
    setChecked(checkedIds.length === allIds.length && checkedIds.length > 0);
  }, [items, allIds, checkedIds]);

  useEffect(() => {
    const items = order && order.items ? order.items : [];
    setItems(items);
  }, [order]);

  if (!hasOrder) {
    return <Loading />;
  }

  return (
    <StyledWrapper>
      <StyledHeader>
        {`Return ${capitalizeFirstLetter(type)} Order ${orderNumber}`}
      </StyledHeader>
      {message ? <StyledMessage>{message}</StyledMessage> : null}
      <StyledTable>
        <StyledTableHead>
          <StyledTableHeadRow>
            <StyledTableHeader>
              <CheckBox
                isHeader
                isChecked={checked}
                showCaption={false}
                marginRight={marginRight}
                caption="check all items"
                onChange={onCheckChange.bind(null, 'all')}
              />
              Name
            </StyledTableHeader>
            <StyledTableHeader>Original Qty</StyledTableHeader>
            <StyledTableHeader>Returned Qty</StyledTableHeader>
            <StyledTableHeader>Condition</StyledTableHeader>
            <StyledTableHeader>Warehouse</StyledTableHeader>
          </StyledTableHeadRow>
        </StyledTableHead>
        <StyledTableBody>
          {items.map(function (item) {
            const {
              id,
              name,
              quantity,
              wareHouse,
              conditions = [],
              attributes = [],
            } = item;
            let returned = 0;
            const data = JSON.stringify({ id, quantity });
            const inputId = `returned-${id}`;
            const isExpanded = expandedRows.includes(id);
            const hasAttributes = attributes && attributes.length > 0;
            if (hasAttributes) {
              returned = attributes.reduce(function (acc, { returned }) {
                const value = returned ? returned : 0;
                return acc + value;
              }, 0);
            } else {
              returned = conditions.reduce(function (acc, { returned }) {
                const value = returned ? returned : 0;
                return acc + value;
              }, 0);
            }
            const select = hasAttributes ? selectInput(id) : null;
            return (
              <Fragment key={id}>
                <StyledTableRow key={id}>
                  <StyledTableData
                    $first
                    style={{ width: '30%' }}
                    onClick={onToggleExpandRow.bind(null, id)}
                  >
                    <CheckBox
                      showCaption={false}
                      marginRight={marginRight}
                      caption={`check ${id}-${name}`}
                      isChecked={checkedIds.includes(id)}
                      onChange={onCheckChange.bind(null, id)}
                    />
                    {name}
                    <StyledArrow $open={isExpanded}>
                      <ChevronIcon />
                    </StyledArrow>
                  </StyledTableData>
                  <StyledTableData>{quantity}</StyledTableData>
                  <StyledTableData>
                    <StyledSrOnlyLabel htmlFor={inputId}>
                      returned-{id}
                    </StyledSrOnlyLabel>
                    <StyledInput
                      disabled
                      id={inputId}
                      type="number"
                      max={quantity}
                      value={returned}
                      ref={inputRefs.current[id]}
                      onChange={onQuantityChange.bind(null, data)}
                    />
                  </StyledTableData>
                  <StyledTableData>{select}</StyledTableData>
                  <StyledTableData>
                    {warehouseInput(id, wareHouse)}
                  </StyledTableData>
                </StyledTableRow>
                {!isExpanded
                  ? null
                  : hasAttributes
                  ? attributes.map(function (attr) {
                      const {
                        quantity,
                        returned,
                        id: attrId,
                        attribute_name: name,
                      } = attr;
                      const inputId = `returned-${attrId}`;
                      const data = JSON.stringify({ id, attrId, quantity });
                      return (
                        <StyledTableRow key={attrId}>
                          <StyledTableData $sub>
                            <CheckBox
                              showCaption={false}
                              caption={`check ${id}`}
                              marginRight={marginRight}
                              isChecked={checkedIds.includes(attrId)}
                              onChange={onCheckChange.bind(null, attrId)}
                            />
                            {`${name} - ${attrId}`}
                          </StyledTableData>
                          <StyledTableData></StyledTableData>
                          <StyledTableData>
                            <StyledSrOnlyLabel htmlFor={inputId}>
                              returned-{attrId}
                            </StyledSrOnlyLabel>
                            <StyledInput
                              disabled
                              id={inputId}
                              type="number"
                              max={quantity}
                              value={returned}
                              ref={inputRefs.current[attrId]}
                              onChange={onQuantityChange.bind(null, data)}
                            />
                          </StyledTableData>
                          <StyledTableData>
                            {selectInput(attrId)}
                          </StyledTableData>
                          <StyledTableData></StyledTableData>
                        </StyledTableRow>
                      );
                    })
                  : conditions.map(function (status) {
                      const { name, id: attrId, quantity, returned } = status;
                      const inputId = `returned-${attrId}`;
                      const data = JSON.stringify({ id, attrId, quantity });
                      return (
                        <StyledTableRow key={attrId}>
                          <StyledTableData $sub>
                            <CheckBox
                              showCaption={false}
                              caption={`check ${id}`}
                              marginRight={marginRight}
                              isChecked={checkedIds.includes(attrId)}
                              onChange={onCheckChange.bind(null, attrId)}
                            />
                            {name}
                          </StyledTableData>
                          <StyledTableData></StyledTableData>
                          <StyledTableData>
                            <StyledSrOnlyLabel htmlFor={inputId}>
                              returned-{attrId}
                            </StyledSrOnlyLabel>
                            <StyledInput
                              id={inputId}
                              type="number"
                              max={quantity}
                              value={returned}
                              ref={inputRefs.current[attrId]}
                              disabled={!checkedIds.includes(attrId)}
                              onChange={onQuantityChange.bind(null, data)}
                            />
                          </StyledTableData>
                          <StyledTableData></StyledTableData>
                          <StyledTableData></StyledTableData>
                        </StyledTableRow>
                      );
                    })}
              </Fragment>
            );
          })}
        </StyledTableBody>
      </StyledTable>
      <StyledButtonRow>
        <StyledButtonWrapper>
          <RoundButton
            caption="Save"
            flexWidth={100}
            icon={<TickIcon />}
            onClick={onReturnOrder}
          />
        </StyledButtonWrapper>
        <StyledButtonWrapper>
          <CancelButton onClick={onCancel} />
        </StyledButtonWrapper>
      </StyledButtonRow>
    </StyledWrapper>
  );
}

export default Return;
