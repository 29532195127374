import { transformActions } from 'utils/';
import { GET_CATEGORIES } from 'actions/types';

const initialState = { data: [], isLoading: false };

/**
 * @description Stores categories array details in redux state
 * @returns {[]}
 * @param {[]} state
 * @param {{}} action
 */
export function category(state = initialState, action) {
  const { type, payload } = action;
  const actions = [GET_CATEGORIES];
  const success = transformActions(actions, 'SUCCESS');
  const failure = transformActions(actions, 'FAILURE');
  if (type === GET_CATEGORIES) {
    return { ...state, isLoading: true };
  }
  if (success.includes(type)) {
    return { data: payload, isLoading: false };
  }
  if (failure.includes(type)) {
    return { ...state, isLoading: false };
  }
  return state;
}
