import { maroon } from 'constants/color';
import styled, { css } from 'styled-components';

export const StyledInput = styled.input`
  --invalidColor: ${({ theme }) => theme.invalidColor};
  --focusedInputColor: ${({ theme }) => theme.focusedInputColor};

  width: 100%;
  display: block;
  margin-top: 5px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 0;
  box-shadow: none;
  appearance: none;
  line-height: 18px;
  padding-left: 12px;
  font-style: normal;
  font-weight: normal;
  -moz-appearance: none;
  background-image: none;
  box-sizing: border-box;
  -webkit-transition: 0.5s;
  -webkit-appearance: none;
  transition: all 0.5s linear;
  height: ${({ height }) => height}px;
  color: ${({ theme }) => theme.textColor};
  border: ${({ theme }) => theme.inputBorder};
  transition: ${({ theme }) => theme.transitionSpeed};
  background-color: ${({ theme }) => theme.inputBackColor};

  &:disabled {
    ${({ theme }) => theme.disabled}
  }

  ${function ({ isInvalid }) {
    return (
      isInvalid &&
      css`
        color: var(--invalidColor);
        border: 1px solid var(--invalidColor);
      `
    );
  }}

  &:focus {
    outline: 0;
    box-shadow: none;
    border: 1px solid;
    color: var(--focusedInputColor);

    ${function ({ isInvalid }) {
      return isInvalid
        ? ({ theme }) => theme.invalidColor
        : ({ theme }) => theme.secondaryColor;
    }};
  }

  ::-moz-placeholder,
  :-ms-input-placeholder,
  ::-webkit-input-placeholder {
    color: #bbb; // change to theme color ?
    opacity: 1;
  }
`;

export const IconWrapper = styled.span`
  padding: 5px;
  display: flex;
  align-items: center;

  svg {
    fill: ${({ theme }) => theme.primaryColor};
  }
`;

export const InputErrorLabel = styled.label`
  color: ${maroon};
  font-size: 12px;
  font-weight: 400;
`;

export const StyledInputWrapper = styled.label`
  position: relative;
`;

export const StyledButton = styled.button`
  --invalidColor: ${({ theme }) => theme.invalidColor};
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  display: flex;
  margin-top: 5px;
  cursor: pointer;
  box-shadow: none;
  margin-bottom: 0;
  border-width: 1px;
  padding: 6px 12px;
  user-select: none;
  line-height: 34px;
  position: absolute;
  align-items: center;
  white-space: nowrap;
  text-transform: none;
  -moz-box-shadow: none;
  -ms-user-select: none;
  -moz-user-select: none;
  background-image: none;
  vertical-align: middle;
  line-height: 1.42857143;
  -webkit-box-shadow: none;
  -webkit-box-shadow: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  border: 1px solid transparent;
  -ms-touch-action: manipulation;
  height: ${({ height }) => height}px;
  color: ${({ theme }) => theme.whiteColor};
  background-color: ${({ theme }) => theme.primaryColor};

  &:disabled {
    ${({ theme }) => theme.disabled}
  }

  ${function ({ isInvalid }) {
    return (
      isInvalid &&
      css`
        background-color: var(--invalidColor);
      `
    );
  }}
`;
