import {
  Map,
  Graph,
  Record,
  Current,
  TopOrder,
  UserOrder,
} from 'components/Order/';
import { findFromArray } from 'utils/';
import React, { useMemo } from 'react';
import {
  StyledWrapper,
  StyledContainer,
  StyledTileWrapper,
} from 'components/Order/List/List.styled';
import { DateFilter } from 'components/Reusable/';
import { useMobileView, useTagOption } from 'hooks/';
import { Collapsible } from 'components/Collapsible/';
import { FlexCol, FlexRow } from 'components/Layout/';
import { ORDER_DATE_FILTER } from 'constants/general';
import { array, bool, func, string } from 'prop-types';
import { CANCELLED, ARCHIVED } from 'constants/inventory';

/**
 * @param {{
 * items : []
 * orders : []
 * customers : []
 * categories : []
 * priceUnit: string
 * onShare : Function
 * isLoading : boolean
 * onArchive : Function
 * onLoadOrders : Function
 * onShowViewModal : Function
 * }} param
 */
function List({
  items,
  orders,
  onShare,
  isLoading,
  onArchive,
  priceUnit,
  customers,
  categories,
  onLoadOrders,
  onShowViewModal,
}) {
  const isMobileView = useMobileView();
  const archived = useTagOption({ name: ARCHIVED });
  const cancelled = useTagOption({ name: CANCELLED });
  const orderDetails = useMemo(() => {
    return orders.map(function (order) {
      return {
        ...order,
        date: new Date(order.createdat).getTime(),
        customer: findFromArray(customers, 'id', order.customer_id),
      };
    });
  }, [orders, isLoading, customers]);

  const activeOrders = useMemo(() => {
    const inactive = [cancelled, archived];
    return orderDetails.filter(function ({ status }) {
      return !inactive.includes(status);
    });
  }, [orderDetails]);

  const topOrders = useMemo(() => {
    const orderItems = [];
    const uniqueItems = [];

    /**
     * @param {string} itemId
     */
    function itemExists(itemId) {
      return uniqueItems.find(function ({ id }) {
        return id === itemId;
      });
    }

    /**
     * @param { string } itemId
     */
    function itemOccurrence(itemId) {
      let occurrence = 0;
      orders.forEach(function ({ items }) {
        items.forEach(function ({ id }) {
          if (id === itemId) {
            occurrence += 1;
          }
        });
      });
      return occurrence;
    }

    /**
     * @param {string} itemId
     */
    function computeQuantity(itemId) {
      const filtered = orderItems.filter(function ({ id }) {
        return id === itemId;
      });
      return filtered.reduce(function (a, b) {
        return a + parseFloat(b.quantity);
      }, 0);
    }

    orders.forEach(function ({ items }) {
      [...items].forEach(function (item) {
        orderItems.push(item);
      });
    });

    orderItems.forEach(function (item) {
      const { id } = item;
      if (!itemExists(id)) {
        const itemObj = findFromArray(items, 'id', id);
        const category = findFromArray(
          categories,
          'id',
          itemObj?.item_category,
        );
        const quantity = computeQuantity(id);
        const totalCost = quantity * parseFloat(itemObj?.purchase_price);
        const occurrence = itemOccurrence(id);
        const updatedItem = {
          ...item,
          quantity,
          totalCost,
          occurrence,
          category: category?.category,
          images: itemObj?.item_images,
        };
        uniqueItems.push(updatedItem);
      }
    });
    return uniqueItems;
  }, [orders, items, categories]);

  const customerOrders = useMemo(() => {
    const orders = [];

    function customerExists(id) {
      return findFromArray(orders, 'id', id);
    }

    function customerOccurrence(customerId) {
      const filtered = orderDetails.filter(function ({ customer }) {
        return customer?.id === customerId;
      });
      return filtered.length;
    }

    function computeTotal(customerId) {
      const filtered = orderDetails.filter(function ({ customer }) {
        return customer?.id === customerId;
      });
      return filtered.reduce(function (a, b) {
        return a + parseFloat(b.total_cost);
      }, 0);
    }

    orderDetails.forEach(function (order) {
      const { customer } = order;
      const { id, name, photo } = customer || {};
      if (!customerExists(id)) {
        orders.push({
          id,
          name,
          photo,
          total: computeTotal(id),
          orders: customerOccurrence(id),
        });
      }
    });
    return orders;
  }, [orderDetails]);

  return (
    <StyledContainer>
      <DateFilter
        marginTop={10}
        marginRight={25}
        isLoading={isLoading}
        onLoadData={onLoadOrders}
        source={ORDER_DATE_FILTER}
      />
      <StyledTileWrapper>
        <FlexRow>
          <FlexCol flexWidth={4}>
            <Current priceUnit={priceUnit} data={activeOrders} />
          </FlexCol>
          <FlexCol flexWidth={4}>
            <TopOrder data={topOrders} />
          </FlexCol>
          <FlexCol flexWidth={4}>
            <UserOrder data={customerOrders} />
          </FlexCol>
        </FlexRow>
      </StyledTileWrapper>
      <Record
        onShare={onShare}
        onArchive={onArchive}
        isLoading={isLoading}
        orders={orderDetails}
        isMobileView={isMobileView}
      />
      <StyledWrapper>
        <Collapsible
          open={false}
          caption="Orders Graph"
          minHeight={isMobileView ? 750 : 560}
        >
          <FlexRow>
            <FlexCol flexWidth={12}>
              <Graph
                orders={orders}
                isLoading={isLoading}
                showDateFilter={false}
                onLoadOrders={onLoadOrders}
              />
            </FlexCol>
          </FlexRow>
        </Collapsible>
      </StyledWrapper>
      <StyledWrapper>
        <Collapsible
          open={false}
          caption="Orders Map"
          minHeight={isMobileView ? 750 : 670}
        >
          <FlexRow>
            <FlexCol flexWidth={12}>
              <Map
                orders={orderDetails}
                isLoading={isLoading}
                showDateFilter={false}
                onLoadOrders={onLoadOrders}
                onShowViewModal={onShowViewModal}
              />
            </FlexCol>
          </FlexRow>
        </Collapsible>
      </StyledWrapper>
    </StyledContainer>
  );
}

List.propTypes = {
  items: array,
  orders: array,
  onShare: func,
  isLoading: bool,
  onArchive: func,
  customers: array,
  categories: array,
  priceUnit: string,
  onLoadOrders: func,
  onShowViewModal: func,
};

export default List;
