import { Map } from 'components/Map/';
import { active } from 'constants/status';
import { func, object } from 'prop-types';
import React, { useState, useEffect } from 'react';
import { company, individual } from 'constants/user';
import { FlexCol, FlexRow } from 'components/Layout/';
import { ReactComponent as ArrowIcon } from 'icons/tick.svg';
import { capitalizeFirstLetter, validateEmail } from 'utils/';
import {
  StyledMapHeader,
  StyledMapWrapper,
} from 'components/Customer/CustomerForm/CustomerForm.styled';
import { CancelButton, RoundButton } from 'components/Buttons/';
import { TextInput, RadioButton, PhotoInput } from 'components/Inputs/';

/**
 * @param {{
 * initialValue: {}
 * onSubmit : Function
 * onModalClose: Function
 * }} param
 */
function CustomerForm({ onSubmit, initialValue, onModalClose }) {
  const {
    photo,
    name = '',
    email = '',
    phone = '',
    address = '',
    type = company,
    location = null,
  } = initialValue || {};
  const [customerState, setCustomerState] = useState({
    status: active,
    name: unescape(name),
    type: unescape(type),
    email: unescape(email),
    phone: unescape(phone),
    address: unescape(address),
    location: JSON.stringify(location),
  });
  const isCompany = customerState.type === company;
  const isIndividual = customerState.type === individual;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [customerErrors, setCustomerErrors] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
  });
  const [customerLogo, setCustomerLogo] = useState(photo);

  /**
   * @param {{
   * currentTarget: {
   * name: string
   * value: string
   * }
   * }} event
   */
  function onInputChange(event) {
    const { currentTarget } = event;
    const { name, value } = currentTarget;
    setCustomerState({
      ...customerState,
      [name]: value,
    });
  }

  function onSubmitCustomer() {
    setIsSubmitting(true);
    const isFormValid = validateCustomerForm();
    if (isFormValid) {
      const formData = new FormData();
      if (customerLogo) {
        formData.append('photo', customerLogo);
      }
      for (const [key, value] of Object.entries(customerState)) {
        if (key === 'email' && !value) {
          continue;
        }
        formData.append(key, value);
      }
      onSubmit(formData);
    }
  }

  function validateCustomerForm() {
    let isFormValid = true;
    const optionalFields = ['email'];
    let errorObject = { ...customerErrors };
    for (const [key, value] of Object.entries(customerState)) {
      if (!optionalFields.includes(key) && !value) {
        isFormValid = false;
        errorObject = {
          ...errorObject,
          [key]: `Incorrect ${capitalizeFirstLetter(key, true)}`,
        };
      } else {
        if (key === 'email' && value) {
          if (!validateEmail(value)) {
            isFormValid = false;
            errorObject = {
              ...errorObject,
              email: 'Invalid Email',
            };
          } else {
            errorObject = {
              ...errorObject,
              [key]: '',
            };
          }
        } else {
          errorObject = {
            ...errorObject,
            [key]: '',
          };
        }
      }
    }
    setCustomerErrors(errorObject);
    return isFormValid;
  }

  /**
   * @param {{}} coordinates
   * @param {string} street
   */
  function onCoordinatesChanged(coordinates, street) {
    const props = { ...customerState };
    const { email } = customerState;
    if (email) {
      props.email = email;
    }
    setCustomerState({
      ...props,
      address: street,
      location: JSON.stringify(coordinates),
    });
  }

  useEffect(() => {
    if (isSubmitting) {
      validateCustomerForm();
    }
  }, [customerState, isSubmitting]);

  return (
    <>
      <FlexRow>
        <FlexCol flexWidth={4}>
          <FlexRow>
            <FlexCol
              flexWidth={12}
              style={{
                marginBottom: '15px',
              }}
            >
              <RadioButton
                name="type"
                value={company}
                caption="Company"
                isChecked={isCompany}
                onChange={onInputChange}
              />
              <RadioButton
                name="type"
                value={individual}
                caption="Individual"
                onChange={onInputChange}
                isChecked={isIndividual}
              />
            </FlexCol>
          </FlexRow>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <TextInput
                isRequired
                name="name"
                inputId="name"
                caption="Name"
                inputType="text"
                placeholder="Enter name"
                onChange={onInputChange}
                value={customerState.name}
                errorMessage={customerErrors.name}
              />
            </FlexCol>
          </FlexRow>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <TextInput
                isRequired
                name="phone"
                inputId="phone"
                caption="Phone"
                inputType="text"
                onChange={onInputChange}
                value={customerState.phone}
                placeholder="e.g +254712345678"
                errorMessage={customerErrors.phone}
              />
            </FlexCol>
          </FlexRow>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <TextInput
                name="email"
                inputId="email"
                caption="Email"
                inputType="text"
                onChange={onInputChange}
                placeholder="Enter email"
                value={customerState.email}
                errorMessage={customerErrors.email}
              />
            </FlexCol>
          </FlexRow>
          <FlexRow>
            <FlexCol flexWidth={7}>
              <PhotoInput
                id="customerLogo"
                imageUri={customerLogo}
                caption="Customer Logo"
                dataTestId="companyLogo"
                onChange={setCustomerLogo}
                buttonText="Choose From Device"
              />
            </FlexCol>
          </FlexRow>
        </FlexCol>
        <FlexCol flexWidth={8}>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <StyledMapHeader>Pick location from Google Maps</StyledMapHeader>
              <StyledMapWrapper>
                <Map
                  centerLatLng={location}
                  onCoordinatesChanged={onCoordinatesChanged}
                />
              </StyledMapWrapper>
            </FlexCol>
          </FlexRow>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <TextInput
                disabled
                isRequired
                name="address"
                inputType="text"
                inputId="address"
                caption="Location"
                onChange={onInputChange}
                value={customerState.address}
                placeholder="Pick location from map"
                errorMessage={customerErrors.address}
              />
            </FlexCol>
          </FlexRow>
        </FlexCol>
      </FlexRow>
      <FlexRow className="modal-buttons-row">
        <RoundButton
          caption="Save"
          flexWidth={100}
          icon={<ArrowIcon />}
          className="modal-button"
          onClick={onSubmitCustomer}
        />
        <CancelButton onClick={onModalClose} />
      </FlexRow>
    </>
  );
}

CustomerForm.defaultProps = {
  initialValue: {},
};

CustomerForm.propTypes = {
  onSubmit: func,
  onModalClose: func,
  initialValue: object,
};

export default CustomerForm;
